import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';
// import { resolve } from "core-js/es6/promise";

var base = 'https://www.domburg800.nl/';

export default createStore({
    state: {
		base: base,
		categories: [],
		dateFormat: { weekday: 'long', month: 'long', day: 'numeric' },
		duration: 650,
		locations: [],
		pages: {},
		siteName: '800 Jaar Domburg',
		sponsors: [],
		sponsorLevels: [],
		structure: {},
        width: 0,
	},
	
	
	getters: {
		base: state => {
			return state.base;
		},
		
		
		categories: state => {
			return state.categories;
		},
		
		
		dateFormat: state => {
			return state.dateFormat;
		},
		
		
		duration: state => {
			return state.duration;
		},


        events: state => {
			let e = [];

			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];

				if (p.template == 4) e.push(p);
			}

            return e;
        },
		
		
		locations: state => {
			return state.locations;
		},
		
		
		pages: state => {
			return state.pages;
		},
		
		
		siteName: state => {
			return state.siteName;
		},
		
		
		sponsors: state => {
			return state.sponsors;
		},
		
		
		sponsorLevels: state => {
			return state.sponsorLevels;
		},
		
		
		structure: state => {
			return state.structure;
		},


        width: state => {
            return state.width;
        },
	},


	mutations: {
		setCategories (state, value)
		{
			state.categories = value;
		},
		
		
		setLocations (state, value)
		{
			state.locations = value;
		},
		
		
		setPages (state, value)
		{
			state.pages = value;
		},
		
		
		setSponsors (state, value)
		{
			state.sponsors = value;
		},
		
		
		setSponsorLevels (state, value)
		{
			state.sponsorLevels = value;
		},
		
		
		setStructure (state, value)
		{
			state.structure = value;
		},


        setWidth(state, value)
        {
            state.width = value;
        },
	},


	actions: {
        _initialize: (context, payload) => {
			return axios.get(base + 'api/initialize/', { params: payload })
			.then(response => {
				context.commit('setCategories', response.data.categories);
				context.commit('setLocations', response.data.locations);
				context.commit('setPages', response.data.pages);
                context.commit('setSponsors', response.data.sponsors);
                context.commit('setSponsorLevels', response.data.sponsorLevels);
                context.commit('setStructure', response.data.structure);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


        updateWidth: ({ commit }, payload) => {
            commit('setWidth', payload);
        },
	},
});

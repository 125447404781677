<template>
    <main :class="isDeluxe ? 'deluxe' : ''">

        <transition name="fade" mode="out-in">

            <loader-element v-if="processing" />

        </transition>


        <hero :page="event">
            <div class="preview">
                <h1>
                    <small>
                        Terugblik op...
                    </small>

                    {{ event.pagetitle }}
                </h1>

                <small class="meta">
                    <span>
                        <i class="far fa-calendar-day"></i>

                        {{ date }}
                    </span>

                    <span v-if="location">
                        <i class="fas fa-map-marker-alt"></i>

                        {{ location }}
                    </span>

                    <span>
                        <i class="fas fa-tag"></i>

                        {{ category }}
                    </span>
                </small>
            </div>
        </hero>


        <section class="white has-aside">
            <aside>
                <ul>
                    <li>
                        <i class="fal fa-calendar-alt"></i>

                        <strong>
                            Wanneer?
                        </strong>

                        {{ dateLong }}
                    </li>

                    <li v-if="location">
                        <i class="fas fa-map-marker-alt"></i>

                        <strong>
                            Waar?
                        </strong>

                        {{ location }}
                    </li>
                    
                    <li v-if="page.variables.hasOwnProperty('photographer')">
                        <i class="fas fa-camera"></i>

                        <strong>
                            Foto's door
                        </strong>

                        {{ page.variables.photographer }}
                    </li>

                    <li v-if="page.variables.hasOwnProperty('photoUrl')">
                        <a target="_blank" class="button secondary" :href="page.variables.photoUrl">
                            Meer foto's
                        </a>
                    </li>
                </ul>
            </aside>


            <div class="content">
                <h2>
                    {{ title }}
                </h2>

                <div class="content" v-html="page.content" />

                <div v-if="!page.variables.hasOwnProperty('extraColumn')" class="tools">
                    <router-link v-if="!hasHistory" :to="{ name: 'RecapOverview' }" class="button secondary">
                        <i class="fas fa-angle-left"></i>

                        <span>
                            Terug naar het overzicht
                        </span>
                    </router-link>

                    <a v-else-if="!page.variables.hasOwnProperty('extraColumn')" href="#" class="button secondary" @click.prevent="goBack">
                        <i class="fas fa-angle-left"></i>
                        <span>
                            Ga terug
                        </span>
                    </a>
                </div>
            </div>
        </section>


        <section v-if="photos.length > 0" class="swiper-section stand-alone first last" id="photos">
            <h2 class="no-icon">
                Gelukkig hebben we de foto's nog
            </h2>
        
            <div class="swiper" style="--index: 0" :data-size="photos.length">
                <a
                    href="#"
                    class="button-back"
                    @click.prevent="swipeBack"
                >
                    <span>
                        <i class="far fa-angle-left"></i>
                    </span>
                </a>
            
                <div
                    v-for="(p, id) in photos"
                    class="swiper-item"
                    :key="id"
                >
                    <a href="#" @click.prevent="photoID = id">
                        <picture>
                            <img loading="lazy" :src="`${base}${p.variables.image}`" :alt="p.pagetitle" />
                        </picture>
                    </a>
                </div>
            
                <a
                    href="#"
                    class="button-next"
                    @click.prevent="swipeForward"
                >
                    <span>
                        <i class="far fa-angle-right"></i>
                    </span>
                </a>
            </div>


            <router-link v-if="link" :to="link.to">
                {{ link.text }}
                <i class="far fa-angle-double-right"></i>
            </router-link>
        </section>


        <section v-if="page.variables.hasOwnProperty('extraColumn')" class="white has-aside">
            <div class="content">
                <div class="content" v-html="page.variables.extraColumn" />
    
                <div class="tools">
                    <router-link v-if="!hasHistory" :to="{ name: 'RecapOverview' }" class="button secondary">
                        <i class="fas fa-angle-left"></i>
                        <span>
                            Terug naar het overzicht
                        </span>
                    </router-link>

                    <a v-else-if="!page.variables.hasOwnProperty('extraColumn')" href="#" class="button secondary" @click.prevent="goBack">
                        <i class="fas fa-angle-left"></i>
                        <span>
                            Ga terug
                        </span>
                    </a>
                </div>
            </div>
        </section>


        <transition name="fade" mode="out-in">
            
            <div class="modal lightbox" v-if="photo" @click.self="closeLightbox">
                <a href="#" class="close" @click.prevent="closeLightbox">
                    <i class="far fa-times-circle"></i>
                </a>

                <transition name="fade" mode="out-in">
                    <img class="lightbox-image" :key="photoID" :src="`${photo.url}`" :alt="photo.title" />
                </transition>

                <div class="tools" v-if="photos.length > 1">
                    <a href="#" @click.prevent="updatePhotoID(-1)">
                        <i class="far fa-angle-left"></i>
                    </a>

                    <span>
                        {{ photoID + 1 }}
                        /
                        {{ photos.length }}
                    </span>

                    <a href="#" @click.prevent="updatePhotoID(1)">
                        <i class="far fa-angle-right"></i>
                    </a>
                </div>
            </div>

        </transition>
        

        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

import Hero from '@/components/HeroSection.vue';
import LoaderElement from '@/components/LoaderElement.vue';

var vm;

export default {
    components: {
        Hero,
        LoaderElement,
    },


    props: {
        page: Object,
    },


    data()
    {
        return {
            index: 0,
            photoID: -1,
        };
    },
    

    computed: {
        ...mapGetters([
            'base',
            'categories',
            'dateFormat',
            'locations',
        ]),


        category()
        {
            for (let i = 0; i < vm.categories.length; i++)
            {
                let c = vm.categories[i];

                if (c.id == vm.event.variables.category)
                {
                    return c.pagetitle;
                }
            }

            return false;
        },


        date()
        {
            let d = new Date(vm.event.variables.date.replace(' ', 'T'));

            return d.toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
        },


        dateLong() {
            let d = new Date(vm.event.variables.date),
                e = Object.prototype.hasOwnProperty.call(vm.event.variables, 'endDate') ? new Date(vm.event.variables.endDate) : false;

            let start = d.toLocaleDateString('nl-NL', vm.dateFormat),
                end = e ? e.toLocaleDateString('nl-NL', vm.dateFormat) : start,
                dates = [start];

            if (start != end)
            {
                dates.push(end);
            }

            return dates.join(' t/m ');
        },


        event()
        {
            return vm.getPageById(vm.page.variables.event);
        },


        hasHistory()
        {
            return window.history.length > 1;
        },


        isDeluxe()
        {
            return Object.prototype.hasOwnProperty.call(vm.event.variables, 'luxury') && vm.event.variables.luxury != 0;
        },


        location()
        {
            for (let i = 0; i < vm.locations.length; i++)
            {
                let l = vm.locations[i];

                if (l.id == vm.event.variables.location)
                {
                    return l.pagetitle;
                }
            }

            return false;
        },


        photo()
        {
            if (vm.photoID < 0) return null;

            let page = vm.photos[vm.photoID];

            return {
                title: page.pagetitle,
                url: `${vm.base}${page.variables.image}`,
            }
        },


        photos()
        {
            return vm.page.sections;
        },


        time()
        {
            let d = new Date(vm.event.variables.date.replace(' ', 'T'));

            return [("0" + d.getHours()).slice(-2), ("0" + d.getMinutes()).slice(-2)].join(':');
        },


        title()
        {
            return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
        }
    },


    methods: {
        closeLightbox()
        {
            vm.photoID = -1;
        },


        getTargetEl(el, tag)
        {
            while (el.tagName.toLowerCase() != tag)
            {
                el = el.parentNode;
            }

            return el;
        },


        getShiftWidth()
        {
            let el = document.querySelector('.swiper-section.first .swiper-item:last-of-type'),
                style = el.currentStyle || window.getComputedStyle(el),
                rems = parseInt(style.getPropertyValue('--gap')),
                gap = rems * parseFloat(getComputedStyle(document.documentElement).fontSize);

            document.querySelector('main').style = '--shift: ' + (el.clientWidth + gap) + 'px';
        },


        onResize()
        {
            vm.getShiftWidth();
            vm.initButtons(null, null);
        },


        swipeBack({target})
        {
            let el = vm.getTargetEl(target, 'a'),
                style = el.currentStyle || window.getComputedStyle(el),
                index = parseInt(style.getPropertyValue('--index')),
                onPage = parseInt(style.getPropertyValue('--onPage'));
            
            vm.updateIndex(index - onPage);
        },


        swipeForward({target})
        {
            let el = vm.getTargetEl(target, 'a'),
                style = el.currentStyle || window.getComputedStyle(el),
                index = parseInt(style.getPropertyValue('--index')),
                onPage = parseInt(style.getPropertyValue('--onPage'));
            
            vm.updateIndex(index + onPage);
        },


        updateIndex(index)
        {
            let elID = 'photos',
                sw = document.querySelector('#' + elID + ' .swiper'),
                size = sw.dataset.size,
                style = sw.currentStyle || window.getComputedStyle(sw),
                onPage = parseInt(style.getPropertyValue('--onPage')),
                max = size > onPage ? size - onPage : 0;
            

            // Make sure the index doesn't exceed the boundaries
            if (index < 0) index = 0;
            if (index > max) index = max;


            // Update the index
            sw.style = '--index: ' + index;


            // Show or hide the buttons
            vm.initButtons(index, elID);
        },


        updatePhotoID(modifier)
        {
            let id = vm.photoID + modifier;

            if (id < 0) id = vm.photos.length - 1;
            else if (id >= vm.photos.length) id = 0;

            vm.photoID = id;
        },
    },


    setup()
    {
        const { getPageById, initButtons } = useFunctions();


        return {
            getPageById,
            initButtons,
        };
    },


    created()
    {
        vm = this;

        window.addEventListener('resize', vm.onResize);
    },


    mounted()
    {
        vm.onResize();
    },


    unmounted()
    {
        window.removeEventListener('resize', vm.onResize);
    },


    watch: {
        photoID(to, from)
        {
            console.log(`photoID set from ${from} to ${to}`);
        },
    }
}
</script>
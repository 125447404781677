<template>
    <main>
        <section class="history">
            <div class="history-item intro">
                <h2>
                    {{ page.pagetitle }}
                </h2>

                <div v-html="page.content" />
            </div>

            <div v-for="(r, id) in recaps" class="history-item" :key="id">
                <router-link :to="{ name: 'RecapPage', params: { alias: r.alias } }">
                    <figure :style="getBgImg(r.event)">
                        <figcaption>
                            <strong>
                                {{ r.pagetitle }}
                            </strong>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </section>
    </main>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    computed:
    {
        recaps()
        {
            let recaps = [];


            for (let i = 0; i < vm.page.sections.length; i++)
            {
                let p = vm.page.sections[i];

                if (p.hidemenu == 0)
                {
                    p.event = vm.getPageById(p.variables.event);

                    recaps.push(p);
                }
            }


            return recaps;
        },
    },


    setup()
    {
        const { getBgImg, getPageById } = useFunctions();


        return {
            getBgImg,
            getPageById,
        };
    },


    created()
    {
        vm = this;
    },
}
</script>
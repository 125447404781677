<template>
    <main>
        <hero :page="page">
            <div class="preview">
                <h1>
                    {{ page.longtitle }}
                </h1>

                <p v-html="page.introtext" />
            </div>
        </hero>


        <section class="white">
            <div class="content" v-html="page.content" />
        </section>


        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import Hero from '@/components/HeroSection.vue';

export default {
    props: {
        page: Object,
    },


    components: {
        Hero,
    },
}
</script>
<template>
    <header :class="open ? 'open' : ''">
        <router-link :to="{ name: 'HomePage' }" exact>
            <logo />
        </router-link>

        <a href="#" class="menu-toggle" @click.prevent="open = !open">
            <span></span>
            <span></span>
            <span></span>
        </a>

        <nav>
            <router-link v-for="(item, id) in nav" :key="id" :to="{ path: item.path }" exact>
                {{ item.name }}
            </router-link>
        </nav>
    </header>
</template>


<script>
import { mapGetters } from 'vuex';

import Logo from '@/components/LogoImg.vue';

var vm;

export default {
    components: {
        Logo,
    },


    data()
    {
        return {
            open: false,
        };
    },


    computed: {
        ...mapGetters([
            'structure',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0 || process.env.NODE_ENV === 'development')
                {
                    if (item.path.length > 1 && item.path.substr(-1) == '/') item.path = item.path.substr(0, item.path.length - 1);

                    nav.push(item);
                }
            }

            return nav;
        },
    },


    created()
    {
        vm = this;
    },


    watch: {
        $route ()
        {
            vm.open = false;
        },
    }
}
</script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useFunctions() {
	const store = useStore();

	const base = computed(() => {
		return store.state.base;
	});

	const pages = computed(() => {
		return store.state.pages;
	});


	function getBgImg(obj, prop)
	{
		if (!obj) return '';

		if (typeof(prop) == 'undefined')
		{
			prop = 'poster';
		}

		let img = '',
			prefix = prop == 'poster' ? 'background-image' : '--' + prop;

		if (Object.prototype.hasOwnProperty.call(obj.variables, prop))
		{
			img = obj.variables[prop];
		}

		return img.length == 0 ? '' : prefix + ": url('" + base.value + img + "')";
	}


    function getPageById(id)
    {
        for (let i = 0; i < pages.value.length; i++)
        {
            let p = pages.value[i];

            if (p.id == id) return p;
        }

        return null;
    }


    function initButtons(index, el)
    {
        let swipers = document.querySelectorAll('.swiper');

        for (let i = 0; i < swipers.length; i++)
        {
            let sw = swipers[i],
                elID = sw.parentNode.id;
            
            if (!el || el == elID)
            {
                let btnBack = document.querySelector('#' + elID + ' .swiper .button-back'),
                    btnNext = document.querySelector('#' + elID + ' .swiper .button-next'),
                    size = sw.dataset.size,
                    style = sw.currentStyle || window.getComputedStyle(sw),
                    onPage = parseInt(style.getPropertyValue('--onPage')),
                    max = size > onPage ? size - onPage : 0;

                if (!index)
                {
                    index = parseInt(style.getPropertyValue('--index'));
                }

                // Show or hide the navigation buttons
                if (index > 0)
                {
                    btnBack.classList.remove('disabled');
                }
                else
                {
                    btnBack.classList.add('disabled');
                }

                if (index < max)
                {
                    btnNext.classList.remove('disabled');
                }
                else
                {
                    btnNext.classList.add('disabled');
                }
            }
        }
    }


	return {
		getBgImg,
        getPageById,
        initButtons,
	};
}
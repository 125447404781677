<template>
    <main :class="isDeluxe ? 'deluxe' : ''">
        
        <transition name="fade" mode="out-in">
        
            <loader-element v-if="processing" />
        
        </transition>


        <hero :page="page">
            <div class="preview">
                <h1>
                    {{ page.pagetitle }}
                </h1>

                <small class="meta">
                    <span>
                        <i class="far fa-calendar-day"></i>

                        {{ date }}
                    </span>

                    <span>
                        <i class="far fa-clock"></i>

                        {{ time }}
                    </span>

                    <span v-if="location">
                        <i class="fas fa-map-marker-alt"></i>

                        {{ location }}
                    </span>

                    <span>
                        <i class="fas fa-tag"></i>

                        {{ category }}
                    </span>
                </small>
            </div>
        </hero>


        <section class="white has-aside">
            <aside>
                <ul>
                    <li>
                        <i class="fal fa-calendar-alt"></i>

                        <strong>
                            Wanneer?
                        </strong>

                        {{ dateLong }}
                    </li>
                    <li>
                        <i class="far fa-clock"></i>

                        <strong>
                            Aanvangstijd?
                        </strong>

                        {{ time }}
                    </li>
                    <li v-if="location">
                        <i class="fas fa-map-marker-alt"></i>

                        <strong>
                            Waar?
                        </strong>

                        {{ location }}
                    </li>
                </ul>


                <div v-if="success === false" class="feedback warning">
                    Helaas konden we je aanmelding niet verzenden. Controleer het formulier op volledigheid en correctheid. Als het
                    probleem zich blijft voordoen, mail dan direct met
                    <a :href="'mailto:' + email">
                        {{ email }}
                    </a>
                </div>
                
                <div v-if="success">
                    We hebben je bericht ontvangen. Deze zullen we z.s.m. proberen te behandelen.
                </div>

                <form v-else-if="regStatus">
                    <h3>
                        Meld je nu aan
                    </h3>

                    <ul>
                        <li class="user">
                            <i class="fas fa-user"></i>
                        
                            <strong>
                                Jouw gegevens
                            </strong>
                        
                            <input type="text" placeholder="Jouw naam..." v-model="form.name" />

                            <input type="email" placeholder="Jouw e-mail..." v-model="form.email" />
                            
                            <input type="text" placeholder="Jouw telefoonnummer..." v-model="form.phone" />
                        </li>

                        <li class="entries">
                            <i class="fas fa-users"></i>

                            <strong>
                                Aantal volwassenen
                            </strong>

                            <input type="number" min="0" v-model="form.adults" />
                            
                            <strong>
                                Aantal kinderen
                            </strong>
                        
                            <input type="number" min="0" v-model="form.children" />
                        </li>

                        <li>
                            <a href="#" class="button" :class="!validForm ? 'disabled' : ''" @click.prevent="submitForm">
                                Aanmelden
                            </a>
                        </li>
                    </ul>

                </form>

            </aside>
        

            <div class="content">
                <h2>
                    {{ page.title }}
                </h2>

                <div class="content" v-html="page.content" />

                <div class="tools">
                    <router-link v-if="!hasHistory" :to="{ name: 'SchedulePage' }" class="button secondary">
                        <span>
                            Bekijk het volledige programma
                        </span>
                        <i class="fas fa-angle-right"></i>
                    </router-link>

                    <a v-else href="#" class="button secondary" @click.prevent="$router.go(-1)">
                        <i class="fas fa-angle-left"></i>
                        <span>
                            Ga terug
                        </span>
                    </a>
                </div>
            </div>
        </section>
        

        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import Hero from '@/components/HeroSection.vue';
import LoaderElement from '@/components/LoaderElement.vue';

var vm;

export default {
    components: {
        Hero,
        LoaderElement,
    },


    props: {
        page: Object,
    },


    data()
    {
        return {
            email: 'domburg800jaarstad@gmail.com',
            form: {
                name: '',
                email: '',
                phone: '',
                adults: 0,
                children: 0,
                event: this.page.id,
            },
            processed: false,
            submitted: false,
            success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'categories',
            'dateFormat',
            'locations',
        ]),


        category()
        {
            for (let i = 0; i < vm.categories.length; i++)
            {
                let c = vm.categories[i];

                if (c.id == vm.page.variables.category)
                {
                    return c.pagetitle;
                }
            }

            return false;
        },


        date()
        {
            let d = new Date(vm.page.variables.date.replace(' ', 'T'));

            return d.toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
        },


        dateLong()
        {
            let d = new Date(vm.page.variables.date),
                e = Object.prototype.hasOwnProperty.call(vm.page.variables, 'endDate') ? new Date(vm.page.variables.endDate) : false;

            let start = d.toLocaleDateString('nl-NL', vm.dateFormat),
                end = e ? e.toLocaleDateString('nl-NL', vm.dateFormat) : start,
                dates = [start];

            if (start != end)
            {
                dates.push(end);
            }

            return dates.join(' t/m ');
        },


        hasHistory()
        {
            return window.history.length > 1;
        },


        isDeluxe()
        {
            return vm.page && Object.keys(vm.page).length > 0 && Object.prototype.hasOwnProperty.call(vm.page.variables, 'luxury') && vm.page.variables.luxury != 0;
        },


        location()
        {
            for (let i = 0; i < vm.locations.length; i++)
            {
                let l = vm.locations[i];

                if (l.id == vm.page.variables.location)
                {
                    return l.pagetitle;
                }
            }

            return false;
        },


        processing() {
            return vm.processed !== vm.submitted;
        },


        regStatus()
        {
            return Object.prototype.hasOwnProperty.call(vm.page.variables, 'registrationStatus') && vm.page.variables.registrationStatus != 0;
        },


        time()
        {
            let d = new Date(vm.page.variables.date.replace(' ', 'T'));

            return [("0" + d.getHours()).slice(-2), ("0" + d.getMinutes()).slice(-2)].join(':');
        },


        validEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(vm.form.email).toLowerCase());
        },


        validPhone()
        {
            let chars = vm.form.phone.split(''),
                numbers = 0;

            for (let i = 0; i < chars.length; i++)
            {
                let c = chars[i];

                if (parseInt(c) == c)
                {
                    numbers++;
                }
            }

            return numbers >= 10;
        },


        validForm()
        {
            let keys = Object.keys(vm.form);

            // Check for empty fields
            for (let i = 0; i < keys.length; i++)
            {
                let k = keys[i];

                if (vm.form[k].length == 0) return false;
            }

            // Make sure at least one person is on added
            if (vm.form.adults <= 0 && vm.form.children <= 0) return false;

            // Check if it's a valid email address
            if (!vm.validEmail) return false;

            // Check if it's a valid phone number
            if (!vm.validPhone) return false;

            // If there's no reason to return false, return true
            return true;
        },
    },


    methods: {
        async submitForm() {
            // Double check if the form is valid
            if (vm.validForm)
            {
                vm.submitted = true;
    
                let formData = new FormData();

                formData.append('action', 'register');
                formData.append('data', JSON.stringify(vm.form));
    
                if (process.env.NODE_ENV == 'development') {
                    setTimeout(() => {
                        vm.success = true;
                        vm.processed = true;
                    }, 2600);
                }
                else {
                    await axios.post(vm.base + 'api/forms/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        vm.processed = true;
                        vm.success = response.data.success;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            }
            else
            {
                alert('Je hebt het formulier nog niet volledig correct ingevuld.')
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>
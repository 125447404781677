<template>
    <main>
        <hero :page="page">
            <div class="img">
                <img :src="base + 'img/logo-full.svg'" alt="Logo" />
            </div>

            <div class="text">
                <h1>
                    {{ page.pagetitle }}
                </h1>

                <p v-html="page.introtext" />
            </div>
        </hero>


        <category class="first last" :events="upcomingEvents" :link="{ text: 'Bekijk het complete programma', to: { name: 'SchedulePage' }}">
            <i class="far fa-calendar-check"></i>

            {{ page.variables.titleSchedule }}
        </category>


        <section class="white">
            <div class="column">
                <div v-html="page.content" />

                <div class="tools">
                    <router-link :to="{ name: 'ContactPage' }" class="button">
                        Lees verder
                        <i class="fas fa-caret-right fa-lg"></i>
                    </router-link>
                </div>
            </div>
        </section>

        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

import Category from '@/components/CategorySwiper.vue';
import Hero from '@/components/HeroSection.vue';

var vm;

export default {
    components: {
        Category,
        Hero,
    },


    props: {
        page: Object,
    },


    computed: {
        ...mapGetters([
            'base',
            'events',
        ]),


        upcomingEvents()
        {
            let ue = {},
                events = [];
                // today = new Date();
            
            // Loop through all events
            for (let i = 0; i < vm.events.length; i++)
            {
                let e = vm.events[i],
                    date = Date.parse(e.variables.date.replace(' ', 'T'));
                
                // Add only those in the future that are public
                // if (date >= today && e.hidemenu == 0)
                if (e.hidemenu == 0)
                {
                    ue[[date,i].join('-')] = e;
                }
            }


            // Order the results be date
            let keys = Object.keys(ue),
                max = Math.min(keys.length, 10);
            keys.sort();

            for (let i = 0; i < max; i++)
            {
                events.push(ue[keys[i]]);
            }


            return events;
        },
    },


	setup()
	{
		const { initButtons } = useFunctions();


		return {
            initButtons,
		};
	},


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.initButtons(0, false);
    },
}
</script>
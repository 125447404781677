<template>

    <section class="cta">
        
        <div class="bg">
            <div class="bg-gradient"></div>
        </div>

        <div class="content">
            <h2>
                Denk met ons mee
            </h2>

            <contact-form />
        </div>

    </section>

</template>


<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
    components: {
		ContactForm,
	},


    props: {
        page: Object,
    },
}
</script>
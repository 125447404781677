<template>
    <section v-if="images.length > 0" class="hero" :class="imgClass" :style="images">
        <div class="bg">
            <div class="bg-gradient"></div>
        </div>
    
        <div class="content">
            <slot></slot>
        </div>
    </section>

    <section v-else class="banner">
        <div class="content">
            <slot></slot>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: {
            type: [Object, Boolean],
            default: false,
        },
    },


    data()
    {
        return {
            imgProps: [
                'image',
                'mobile',
            ],
        };
    },


    computed: {
        ...mapGetters([
            'categories',
            'dateFormat',
        ]),


        backBtnState()
        {
            return false;
        },


        images()
        {
            let imgs = [];

            if (vm.page)
            {
                console.log(vm.page);
                for (let i = 0; i < vm.imgProps.length; i++)
                {
                    let p = vm.imgProps[i],
                        img = vm.getBgImg(vm.page, p);
                    
                    if (img.length > 0) imgs.push(img);
                }
            }

            return imgs.join('; ');
        },


        imgClass()
        {
            return vm.images != '' ? 'has-img' : '';
        },


        nextBtnState()
        {
            return true;
        },
    },


    methods: {
        getCategory(id)
        {
            for (let i = 0; i < vm.categories.length; i++)
            {
                let c = vm.categories[i];

                if (c.id == id) return c.alias;
            }

            return '';
        },


        getEventDate(e)
        {
            let d = new Date(e.variables.date);

            return d.toLocaleDateString('nl-NL', vm.dateFormat);
        },
    },


	setup()
	{
		const { getBgImg } = useFunctions();


		return {
			getBgImg,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>
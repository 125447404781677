<template>
	<transition-group name="load" mode="out-in">
		<article v-if="loaded" key="path" @click="onClick">
			<teleport to="head">
				<title>
					{{ meta.title }}
				</title>
				
				<meta name="description" :content="meta.description">
				<meta name="keywords" :content="meta.keywords">
				
				<meta property="og:description" :content="meta.description">
				<meta property="og:title" :content="meta.title" />
				<meta property="og:image" :content="meta.image" />
			</teleport>
			

			<app-header />
			

			<router-view v-if="page !== {}" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component
						:is="Component"
						:key="routePath"
						:page="page"
						:photo="photo"
						@cartUpdate="playCartAnimation"
						@setProduct="setProduct"
						@logout="logoutUser"
					>
                        <template v-slot:sponsors>
                            <section class="sponsors">
                                <h2>
                                    Ons initiatief wordt gesteund door...
                                </h2>

                                <div class="sponsor" v-for="(s, id) in randomSponsors" :key="id">
                                    <a v-if="s.variables.hasOwnProperty('website')" :href="s.variables.website" target="_blank">
                                        <img v-if="s.variables.hasOwnProperty('image')" :alt="s.pagetitle" :src="base + s.variables.image" loading="lazy" />
                                        <span v-else>
                                            {{ s.pagetitle }}
                                        </span>
                                    </a>

                                    <span v-else>
                                        <img v-if="s.variables.hasOwnProperty('image')" :alt="s.pagetitle" :src="base + s.variables.image" loading="lazy" />
                                        <span v-else>
                                            {{ s.pagetitle }}
                                        </span>
                                    </span>
                                </div>
                            </section>
                        </template>

                        <template v-slot:cta>
                            <pitch />
                        </template>
                    </component>
				</transition>
			</router-view>
			

			<app-footer />
			
		</article>


		<loader-element v-else key="loader" />

	</transition-group>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import usePage from '@/hooks/page.js';

import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import LoaderElement from '@/components/LoaderElement.vue';
import Pitch from '@/components/PitchSection.vue';

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
		LoaderElement,
        Pitch,
	},


	data()
	{
		return {
			randomSponsors: [],
		};
	},


	computed: {
		...mapGetters([
			'base',
			'duration',
			'pages',
			'siteName',
			'sponsors',
			'structure',
		]),


		loaded()
		{
			let s = Object.keys(vm.structure),
				p = Object.keys(vm.pages);
			
			return s.length > 0 && p.length > 0;
		},


		meta()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0 || Object.prototype.hasOwnProperty.call(vm.page, 'temp')) return false;

			let view = vm.page,
                title = view.title + ' | ' + vm.siteName,
                img = view.hasImage ? view.variables.image : 'img/icons/android-chrome-512x512.png',
                meta = {
                    description: view.description,
                    keywords: view.keywords,
                    title: title,
                    image: img,
                };
			

			return meta;
		},


		routePath()
		{
			let p = vm.$route.fullPath;

			return p;
		},
	},


	methods: {
		...mapActions([
			'_initialize',
            'updateWidth',
		]),


		randomizeSponsors()
		{
			let items = [];

            for (let i = 0; i < vm.sponsors.length; i++)
            {
                let s = vm.sponsors[i];

                if (s.variables.sponsorLevel == 58)
                {
                    items.push(s);
                }
            }

			let	shuffled = [...items].sort(() => 0.5 - Math.random());
				
			vm.randomSponsors = shuffled.slice(0, 6);
		},


        resizeListener()
        {
            // First of all, trigger the scroll reveal
            vm.scrollReveal();

            // Then update the screen width
            vm.updateWidth(document.body.scrollWidth);
        },

		
		scrollReveal()
		{
            let header = document.querySelector('header'),
                revealPoint = Math.round(window.innerHeight / 4),
                reveals = document.querySelectorAll('.reveal'),
                sections = document.querySelectorAll('main > section'),
                wHeight = window.innerHeight;

            // First look for the header styling
            for(let i = 0; i < sections.length; i++)
            {
				let s = sections[i],
                    // sHeight = s.innerHeight,
                    sBottom = s.getBoundingClientRect().bottom,
                    sTop = s.getBoundingClientRect().top;

				if(sTop < (header.clientHeight / 2) && sBottom > 0)
				{
                    if (s.className.indexOf('white') >= 0)
                    {
                        header.classList.add('on-white');
                    }
                    else
                    {
                        header.classList.remove('on-white');
                    }
				}
			}


            // Then look for elements to reveal 
			for(let i = 0; i < reveals.length; i++)
            {
				let revealTop = reveals[i].getBoundingClientRect().top;

				if(revealTop < wHeight - revealPoint)
				{
					reveals[i].classList.add('active');
				}
				else
				{
					reveals[i].classList.remove('active');
				}
			}
		},
	},


	setup()
	{
		const { page } = usePage();


		return {
			page,
		};
	},


	created()
	{
		vm = this;

        vm.updateWidth(document.body.scrollWidth);
		
		vm._initialize()
		.then(() => {
			vm.randomizeSponsors();
		});

		window.addEventListener('scroll', vm.scrollReveal);
		window.addEventListener('resize', vm.resizeListener);
	},


	mounted()
	{
		setTimeout(vm.scrollReveal, vm.duration);
	},
	

	unmounted()
	{
		window.removeEventListener('scroll', vm.scrollReveal);
		window.removeEventListener('resize', vm.resizeListener);
	},


    watch: {
        $route ()
        {
            vm.randomizeSponsors();
        },
    }
};
</script>


<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/scss/app.scss';
</style>

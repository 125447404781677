<template>
    <section class="swiper-section" :id="tag" :class="tag">
        <h2>
            <slot></slot>
        </h2>
        
        <div class="swiper" style="--index: 0" :data-size="events.length">
            <a
                href="#"
                class="button-back"
                :data-id="tag"
                @click.prevent="swipeBack"
            >
                <span>
                    <i class="far fa-angle-left"></i>
                </span>
            </a>
            
            <div
                v-for="(e, id) in events"
                class="swiper-item"
                :key="id"
                :class="getCategory(e.variables.category)"
            >
                <router-link :to="{ name: 'EventPage', params: { alias: e.alias } }">
                    <figure :style="getBgImg(e)">
                        <figcaption>
                            <strong>
                                {{ e.pagetitle }}
                            </strong>
                            
                            <small>
                                {{ getEventDate(e) }}
                            </small>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
            
            <a
                href="#"
                class="button-next"
                :data-id="tag"
                @click.prevent="swipeForward"
            >
                <span>
                    <i class="far fa-angle-right"></i>
                </span>
            </a>
        </div>
        
        <router-link v-if="link" :to="link.to">
            {{ link.text }}
            <i class="far fa-angle-double-right"></i>
        </router-link>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        events: Array,
        link: {
            type: [Object, Boolean],
            default: false,
        },
        tag: {
            type: String,
            default: 'my-swiper',
        },
    },


    data()
    {
        return {
            index: 0,
            shiftWidth: 0,
        };
    },


    computed: {
        ...mapGetters([
            'categories',
            'dateFormat',
            'width',
        ]),
    },


    methods: {
        getCategory(id)
        {
            for (let i = 0; i < vm.categories.length; i++)
            {
                let c = vm.categories[i];

                if (c.id == id) return c.alias;
            }

            return '';
        },


        getEventDate(e)
        {
            let d = new Date(e.variables.date.replace(' ', 'T'));

            return d.toLocaleDateString('nl-NL', vm.dateFormat);
        },


        getTargetEl(el, tag)
        {
            while (el.tagName.toLowerCase() != tag)
            {
                el = el.parentNode;
            }

            return el;
        },


        getShiftWidth()
        {
            let el = document.querySelector('.swiper-section.first .swiper-item:last-of-type'),
                style = el.currentStyle || window.getComputedStyle(el);

            document.querySelector('main').style = '--shift: ' + (el.clientWidth + parseFloat(style.marginLeft)) + 'px';
        },


        onResize()
        {
            vm.getShiftWidth();
            vm.initButtons(null, null);
        },


        swipeBack({target})
        {
            let el = vm.getTargetEl(target, 'a'),
                style = el.currentStyle || window.getComputedStyle(el),
                index = parseInt(style.getPropertyValue('--index')),
                onPage = parseInt(style.getPropertyValue('--onPage'));
            
            vm.updateIndex(index - onPage, el.dataset.id);
        },


        swipeForward({target})
        {
            let el = vm.getTargetEl(target, 'a'),
                style = el.currentStyle || window.getComputedStyle(el),
                index = parseInt(style.getPropertyValue('--index')),
                onPage = parseInt(style.getPropertyValue('--onPage'));
            
            vm.updateIndex(index + onPage, el.dataset.id);
        },


        updateIndex(index, elID)
        {
            let sw = document.querySelector('#' + elID + ' .swiper'),
                size = sw.dataset.size,
                style = sw.currentStyle || window.getComputedStyle(sw),
                onPage = parseInt(style.getPropertyValue('--onPage')),
                max = size > onPage ? size - onPage : 0;
            

            // Make sure the index doesn't exceed the boundaries
            if (index < 0) index = 0;
            if (index > max) index = max;


            // Update the index
            sw.style = '--index: ' + index;


            // Show or hide the buttons
            vm.initButtons(index, elID);
        },
    },


	setup()
	{
		const { getBgImg, initButtons } = useFunctions();


		return {
			getBgImg,
            initButtons,
		};
	},


    created()
    {
        vm = this;

        window.addEventListener('resize', vm.onResize);
    },


    mounted()
    {
        vm.onResize();
    },


    unmounted()
    {
        window.removeEventListener('resize', vm.onResize);
    }
}
</script>
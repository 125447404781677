<template>
    <main>
        <hero :page="page">
            <div class="preview">
                <h1>
                    {{ page.pagetitle }}
                </h1>

                <p v-html="page.introtext" />
            </div>
        </hero>


        <section class="white" :class="page.variables.hasOwnProperty('extraColumn') ? 'has-aside' : ''">
            
            <aside v-if="page.variables.hasOwnProperty('extraColumn')" class="contact" v-html="page.variables.extraColumn" />

            <div class="content" v-html="page.content" />

        </section>


        <slot name="sponsors"></slot>


        <section class="cta">
            <div class="bg">
                <div class="bg-gradient"></div>
            </div>

            <div class="content">
                <h2>
                    {{ page.variables.titleContact }}
                </h2>

                <contact-form />
            </div>
        </section>
    </main>
</template>


<script>
import ContactForm from '@/components/ContactForm.vue';
import Hero from '@/components/HeroSection.vue';

export default {
    props: {
        page: Object,
    },


    components: {
        ContactForm,
        Hero,
    },
}
</script>
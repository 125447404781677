<template>
    <svg version="1.1" class="logo" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="62px" height="60px" viewBox="0 0 62 60" style="enable-background:new 0 0 62 60;" xml:space="preserve">
    <path id="D_00000165233799021971990750000006536525925170299024_" class="st0" d="M55.6,25.5c0,8.9-3.5,15.8-9.2,20.6
        c-0.5-0.3-1-0.5-1.6-0.4c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.6-0.1c-0.4-0.1-0.9-0.1-1.4-0.1c-1.1,0-2.2,0.3-3.2,0.9
        c-0.3,0.2-0.5,0.3-0.8,0.5c0-0.1,0-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.4-0.6,0.7-1.3,0.7-2c0-0.7-0.2-1.3-0.6-1.8
        c2.9-3.8,4.1-9.2,4.1-16c0-7.8-2.1-14.6-5.9-18.2c-3.5-3.5-7.7-4.6-13.2-4.6h-2.2c-2,0-3.7,1.6-3.7,3.7V47c0,0-0.1,0.1-0.1,0.1l0,0
        c-0.5-0.6-1.2-1.1-1.9-1.5c-0.8-0.4-1.6-0.5-2.6-0.5c-1,0-1.9,0.2-2.7,0.6c-0.3,0.1-0.5,0.3-0.7,0.4c0-1.3-0.6-2.5-1.6-3.3
        c-0.5-0.4-1.1-0.7-1.8-0.9v-38H2.1V0.4h22.6c10.5,0,18.3,2.2,23.5,7.2C52.4,11.4,55.6,17.2,55.6,25.5z"/>
    <path id="icon_00000178163148732218959100000006683789369346602412_" class="st0" d="M37.3,41.5c0-0.1,0-0.2,0-0.2
        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.2-0.5-0.4-0.8c-0.7-1.4-0.6-3-0.7-4.5c0-1.7-0.2-3.3,0-5c0.1-0.1,0-0.2,0-0.3c0,0,0,0,0,0
        c0-0.2,0.3-0.3,0.3-0.5c0.1-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2,0,0.3-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-2,0-2c-0.6-0.2-1.2-0.3-1.8-0.4
        c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-0.3,0.1c0,0.4,0,0.8,0,1.2c-0.4-0.1-0.9-0.2-1.4-0.2c0-0.5,0-2.1,0-2.6c0,0-0.1-0.1-0.1-0.1
        c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0-1.5,0-1.6c-0.4-0.1-0.9-0.1-1.3-0.2c0,0.2,0,0.8-0.1,1c-0.1,0.1-0.4,0-0.5,0
        c-0.9-0.1-0.6,0.2-0.6-1.1c-0.7-0.1-1.5-0.1-2.2,0c0,1.3,0.3,1-0.6,1.1c-0.1,0-0.4,0.1-0.5,0c0-0.2,0-0.9-0.1-1
        c-0.4,0.1-0.9,0.1-1.3,0.2c0,0.1-0.1,1.5,0,1.6c0.1,0.1,0.2,0.3,0.2,0.4c0,0-0.1,0.1-0.1,0.1c0,0.5,0,2.1,0,2.6
        c-0.5,0.1-0.9,0.1-1.4,0.2c0-0.4,0-0.8,0-1.2l-0.3-0.1c-0.6,0.2-1.2,0.3-1.9,0.4c0,0,0,1.9,0,2c0.1,0.1,0.2,0.1,0.3,0.1
        c0.2,0,0.1,0.3,0.2,0.4c0,0.2,0.3,0.3,0.3,0.4c0,0.1-0.1,0.3,0,0.3c0,0,0.1,0.1,0.1,0.1l0,0c0.2,1.7,0.1,3.5,0,5.3
        c-0.1,1.4,0,2.9-0.7,4.1c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c0.1,1.3,0,2.6,0,3.9c1.5-0.1,3,0.4,4.2,1.3h0
        c0.3,0.3,0.6,0.6,0.9,1c0-0.5,0.1-0.8,0.1-4.9h0.3c0,0.3,0.1,0.5,0.2,0.8c0.1-0.2,0.3-0.5,0.3-0.8h0.4c0,0.3,0.1,0.6,0.2,0.8
        c0.1-0.3,0.2-0.5,0.3-0.8h0.5c0,0.3,0.2,0.6,0.3,0.9c0.1-0.3,0.3-0.5,0.3-0.9h0.5c0,0.3,0.2,0.5,0.3,0.8c0.1-0.3,0.3-0.5,0.2-0.8
        h0.4c0,0.3,0.2,0.5,0.3,0.8c0.1-0.2,0.2-0.5,0.2-0.8h0.3c0,3.6,0,3.5,0.1,4.5h0c0.3-0.5,0.9-0.9,1.5-1c-0.8-2.2,1.2-4.7,3.5-4.2
        C37.3,41.8,37.3,41.6,37.3,41.5z M28.8,25C28.8,24.9,28.8,24.9,28.8,25c0.4-0.1,1.2-0.1,1.5-0.1c0.1,0,0.2,0,0.2,0.1l0,0
        c-0.1,1.7,0.3,0.9-1.6,1.1c-0.2,0-0.1-0.1-0.1-0.3C28.8,25.5,28.8,25.3,28.8,25z M31.5,36.1c0,0.1-0.1,0.1-0.1,0
        c-1.3-0.1-2.2-0.1-3.5,0c0,0-0.1,0-0.1,0c0,0-0.1-3.3-0.1-3.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c1.1-0.2,2.6-0.2,3.7,0
        C31.7,32.5,31.5,36,31.5,36.1z"/>
    <g id="naam">
        <path class="st0" d="M5.6,49.1c0.6-0.4,1.2-0.8,1.5-1.3c0.4-0.5,0.7-1,0.7-1.7c0-0.9-0.5-1.7-1.4-2.1c-0.5-0.2-1-0.3-1.7-0.3
            c-2,0-3.4,1.1-3.4,2.6c0,0.5,0.2,1,0.4,1.4c0.4,0.6,1,1.2,1.6,1.8C3.2,49.7,3,49.8,2.8,50c-0.3,0.2-0.5,0.4-0.7,0.6
            C1.5,51.2,1,51.9,1,52.9c0,2,1.8,2.9,3.5,2.9c1.6,0,2.9-0.8,3.5-1.9c0.2-0.4,0.3-0.7,0.3-1.1C8.2,51.2,6.9,50.1,5.6,49.1z
            M3.1,45.9c0-0.9,0.8-1.5,1.5-1.5c0.8,0,1.5,0.6,1.5,1.9c0,0.5-0.1,1.1-0.4,1.5c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3-0.3-0.6-0.5-0.9-0.8
            C3.6,47.2,3.1,46.6,3.1,45.9L3.1,45.9z M6.4,53.9c-0.3,0.8-1.1,1.3-1.8,1.3c-0.7,0-1.4-0.4-1.7-1.3c-0.1-0.3-0.2-0.8-0.2-1.2
            c0-1.3,0.6-2,1.3-2.6c1.2,1,2.5,2,2.5,3.2C6.5,53.5,6.5,53.7,6.4,53.9z"/>
        <path class="st0" d="M15.5,47.8c-0.6-0.4-1.4-0.7-2.3-0.7c-0.9,0-1.7,0.3-2.3,0.7c-1.2,0.8-1.8,2.3-1.8,3.8c0,0.8,0.2,1.6,0.6,2.3
            c0.6,1.1,1.7,1.9,3.3,1.9c1.6,0,2.8-0.8,3.5-1.9c0.5-0.8,0.7-1.7,0.7-2.6C17.2,49.9,16.6,48.6,15.5,47.8z M14.8,53.9
            c-0.4,0.6-0.9,1-1.6,1c-0.7,0-1.3-0.4-1.6-1c-0.4-0.6-0.6-1.5-0.6-2.4c0-1.7,0.7-3.4,2.2-3.4c1.5,0,2.2,1.7,2.2,3.4
            C15.4,52.3,15.2,53.2,14.8,53.9z"/>
        <path class="st0" d="M25.6,48.9c-0.3-0.4-0.6-0.8-1-1.1c-0.6-0.4-1.4-0.7-2.3-0.7c-0.1,0-0.3,0-0.4,0c-0.7,0.1-1.4,0.3-1.9,0.7
            c-0.8,0.5-1.3,1.3-1.6,2.2c-0.2,0.5-0.2,1-0.2,1.6c0,0.8,0.2,1.6,0.6,2.3c0.6,1.1,1.7,1.9,3.3,1.9c1.7,0,2.9-0.9,3.5-2.1
            c0.4-0.8,0.6-1.6,0.6-2.5c0-0.6-0.1-1.2-0.4-1.8C25.8,49.2,25.7,49.1,25.6,48.9z M23.9,53.8c-0.4,0.6-0.9,1-1.6,1
            c-0.7,0-1.3-0.4-1.6-1c-0.4-0.6-0.6-1.5-0.6-2.4c0-0.5,0.1-1,0.2-1.5c0.3-1,0.8-1.7,1.7-1.9c0.1,0,0.2,0,0.3,0
            c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.6,0.6,1.1c0.2,0.5,0.3,1.2,0.3,1.8C24.4,52.3,24.2,53.2,23.9,53.8L23.9,53.8z"/>
        <path class="st0" d="M37.3,44.1c-0.1-0.2-0.3-0.3-0.6-0.3c-0.8,0-1.1,0.7-1.1,1.2c0,0.3,0.1,0.5,0.4,0.6c0.1,0,0.2,0.1,0.3,0.1
            c0.6,0,0.9-0.4,1.1-0.9c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1C37.4,44.2,37.4,44.1,37.3,44.1z"/>
        <path class="st0" d="M34.9,47.8c-0.3,0.1-0.6,0.1-0.9,0.2v0.5h0.4c0.4,0,0.6,0.1,0.6,0.3c0,0.1,0.1,0.2,0.1,0.3
            c0,0.2-0.1,0.6-0.1,0.7l-0.6,2.6l-0.8,3.7c-0.4,1.7-1.5,3.1-2.4,3.3l0.1,0.4c1.7,0,3.3-1.7,3.9-4.6l0.7-3.2l0.4-1.7
            c0.1-0.3,0.2-0.9,0.3-1.6c0-0.2,0.1-0.4,0.1-0.7c0-0.2,0-0.4,0.1-0.6l-0.1-0.1C36.4,47.4,35.7,47.6,34.9,47.8z"/>
        <path class="st0" d="M44.7,49.4c0.2-0.9,0.4-1.4,0.5-1.6c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0
            c-0.6,0-1.1-0.2-1.8-0.2c-0.6,0-1.2,0.1-1.8,0.4c-1.3,0.6-2.3,1.9-2.8,3.2c-0.3,0.8-0.5,1.6-0.5,2.3c0,1.6,0.8,2.4,1.8,2.4
            c1.7,0,3.1-2.3,3.1-2.3h0c0,0-0.2,0.8-0.2,1.4c0,0.5,0.2,0.9,0.8,0.9s1.4-0.4,2.1-1l-0.1-0.5c-0.3,0.3-0.8,0.6-1.1,0.6
            c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0.1-0.5,0.2-1.3L44.7,49.4z M43.1,50.5c-0.4,1.9-1.7,4.2-3.1,4.2c-0.5,0-0.8-0.3-0.8-1.5
            c0-1,0.3-2,0.7-2.9c0.6-1.3,1.7-2.3,2.6-2.3c0.4,0,0.8,0.2,1,0.3c0,0,0.1,0.1,0.1,0.1L43.1,50.5z"/>
        <path class="st0" d="M53.3,49.4c0.2-0.9,0.4-1.4,0.5-1.6c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.2,0-0.3,0.1-0.5,0.1
            c-0.7,0-1.3-0.2-2.1-0.2c-0.6,0-1.2,0.1-1.8,0.4c-2.1,1-3.2,3.6-3.2,5.5c0,1.6,0.8,2.4,1.8,2.4c1.7,0,3.1-2.3,3.1-2.3h0
            c0,0-0.2,0.8-0.2,1.4c0,0.5,0.2,0.9,0.8,0.9c0.7,0,1.4-0.4,2.1-1l-0.1-0.5c-0.3,0.3-0.8,0.6-1.1,0.6c-0.2,0-0.4-0.1-0.4-0.4
            c0-0.2,0.1-0.5,0.2-1.3L53.3,49.4z M51.7,50.5c-0.4,1.9-1.7,4.2-3.1,4.2c-0.5,0-0.8-0.3-0.8-1.5c0-2.6,1.7-5.2,3.3-5.2
            c0.6,0,1.1,0.4,1.1,0.4L51.7,50.5z"/>
        <path class="st0" d="M60.9,47.8c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.6,0.2-0.9,0.4c-0.6,0.5-1.2,1.3-1.6,2.1h0c0,0,0.4-1.1,0.5-2.1
            c0-0.2,0-0.4,0-0.6l-0.1-0.1c-0.5,0.3-1.1,0.5-1.7,0.6c-0.3,0.1-0.6,0.1-0.9,0.2v0.5h0.7c0.3,0,0.4,0.2,0.4,0.5
            c0,0.6-1.3,5.2-1.3,6.7l1.4-0.1c0.1-1,0.2-1.8,0.4-2.8l0.2-0.9c0.2-0.9,1.3-3,2.1-3c0.3,0,0.4,0.2,0.7,0.2c0.5,0,0.8-0.4,0.8-0.8
            C61,48,61,47.9,60.9,47.8z"/>
    </g>
    </svg>
</template>

import { createRouter, createWebHistory } from "vue-router";

import Contact from "@/views/ContactView.vue";
import DefaultPage from "@/views/DefaultView.vue";
import Event from "@/views/EventView.vue";
import History from "@/views/HistoryView.vue";
import Home from "@/views/HomeView.vue";
import Recap from "@/views/RecapView.vue";
import RecapOverview from "@/views/RecapOverviewView.vue";
import Schedule from "@/views/ScheduleView.vue";
import Sponsor from "@/views/SponsorView.vue";
import Timeline from "@/views/TimelineView.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: Home,
    },
	
    // {
    //     path: "/boek-van-domburg",
    //     name: "BookPage",
    //     component: Timeline,
    // },
	
    {
        path: "/contact",
        name: "ContactPage",
        component: Contact,
    },
	
    // {
    //     path: "/domburgse-koppen",
    //     name: "FacesPage",
    //     component: Timeline,
    // },

    {
        path: "/historie",
        name: "HistoryPage",
        component: History,
    },

    {
        path: "/historie/:alias",
        name: "TimelinePage",
        component: Timeline,
    },
	
    {
        path: "/ondersteuners",
        name: "SponsorPage",
        component: Sponsor,
    },
	
    {
        path: "/programma",
        name: "SchedulePage",
        component: Schedule,
        // meta: {
        //     showModal: false,
        // },
        // children: [{
        //     path: ":alias",
        //     name: "EventPage",
        //     component: Event,
        //     // meta: {
        //     //     showModal: true,
        //     // }
        // }]
    },
	
    {
        path: "/programma/:alias",
        name: "EventPage",
        component: Event,
    },
	
    {
        path: "/terugblikken",
        name: "RecapOverview",
        component: RecapOverview,
    },

    {
        path: "/terugblikken/:alias",
        name: "RecapPage",
        component: Recap,
    },
	
    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        if (to)
        {
          return savedPosition ? savedPosition : { top: 0 };
        }
        else
        {
          return {};
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;

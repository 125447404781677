<template>
    <main>
        <hero :page="page">
            <div class="preview">
                <h1>
                    {{ page.longtitle }}
                </h1>

                <p v-html="page.introtext" />
            </div>
        </hero>


        <section class="white">
            <div class="content narrow" v-html="page.content" />
            
            <div class="sponsor-levels">
                <div class="level" v-for="(sl, id) in sponsorLevels" :key="id" :class="sl.alias">
                    <h3 v-html="sl.pagetitle" />

                    <small v-html="sl.introtext" />
                    
                    <div v-html="sl.content" />
                </div>
            </div>
            
            <div v-if="page.variables.hasOwnProperty('extraColumn')" class="content narrow" v-html="page.variables.extraColumn" />
        </section>


        <section class="sponsors">
            <h2>
                Ons initiatief wordt gesteund door...
            </h2>

            <div class="sponsor" v-for="(s, id) in orderedSponsors" :key="id">
                <a v-if="s.variables.hasOwnProperty('website')" :href="s.variables.website" target="_blank">
                    <img v-if="s.variables.hasOwnProperty('image')" :alt="s.pagetitle" :src="base + s.variables.image" loading="lazy" />
                    <span v-else>
                        {{ s.pagetitle }}
                    </span>
                </a>

                <span v-else>
                    <img v-if="s.variables.hasOwnProperty('image')" :alt="s.pagetitle" :src="base + s.variables.image" loading="lazy" />
                    <span v-else>
                        {{ s.pagetitle }}
                    </span>
                </span>
            </div>
        </section>


        <section class="cta">
            <div class="bg">
                <div class="bg-gradient"></div>
            </div>

            <div class="content">
                <h2>
                    {{ page.variables.titleContact }}
                </h2>

                <contact-form />
            </div>
        </section>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import ContactForm from '@/components/ContactForm.vue';
import Hero from '@/components/HeroSection.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        ContactForm,
        Hero,
    },


    computed: {
        ...mapGetters([
            'base',
            'sponsors',
            'sponsorLevels',
        ]),


        orderedSponsors()
		{
			let medium = [],
                other = [],
                top = [];

            for (let i = 0; i < vm.sponsors.length; i++)
            {
                let s = vm.sponsors[i];

                if (s.variables.sponsorLevel == 58)
                {
                    top.push(s);
                }
                else if (s.variables.sponsorLevel == 57)
                {
                    medium.push(s);
                }
                else
                {
                    other.push(s);
                }
            }

			let	shuffledM = [...medium].sort(() => 0.5 - Math.random()),
                shuffledT = [...top].sort(() => 0.5 - Math.random());
            
            let sponsors = shuffledT.concat(shuffledM);

            return sponsors;
		},
    },


    created()
    {
        vm = this;
    },
}
</script>
<template>
    <footer>
        <div>
            <router-link v-for="(item, id) in nav" :key="id" :to="{ path: item.path.slice(0, -1) }">
                {{ item.pagetitle }}
            </router-link>

            <a href="/assets/media/files/organisatie.pdf" target="_blank">
                Organisatie
            </a>
        </div>

        <div class="credits">
            <span>
                Copyright &copy; {{ year }}
            </span>

            <span>
                Realisatie:

                <a href="https://dizero.nl">
                    Dizero
                </a>
            </span>
        </div>
    </footer>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    data()
    {
        return {
            year: new Date().getFullYear(),
        };
    },


    computed: {
        ...mapGetters([
            'pages',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.pages.length; i++)
            {
                let p = vm.pages[i],
                    vars = p.variables,
                    keys = Object.keys(vars);

                if (keys.indexOf('footerLink') >= 0)
                {
                    nav.push(p);
                }
            }

            return nav;
        }
    },


    created()
    {
        vm = this;
    },
}
</script>
<template>
    <main>
        <section class="history">
            <div class="history-item intro">
                <h2>
                    {{ page.pagetitle }}
                </h2>

                <div v-html="page.content" />
            </div>

            <div v-for="(tl, id) in timelines" class="history-item" :key="id">
                <router-link :to="{ name: 'TimelinePage', params: { alias: tl.alias } }">
                    <figure :style="getBgImg(tl)">
                        <figcaption>
                            <strong>
                                {{ tl.pagetitle }}
                            </strong>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </section>
    </main>
</template>


<script>
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    computed: {
        timelines()
        {
            let tls = [];
            

            for (let i = 0; i < vm.page.sections.length; i++)
            {
                let tl = vm.page.sections[i];

                if (tl.hidemenu == 0)
                {
                    tls.push(tl);
                }
            }


            return tls;
        },
    },


	setup()
	{
		const { getBgImg } = useFunctions();


		return {
			getBgImg,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>
<template>
    <main>
        <hero :page="page">
            <div class="preview">
                <h1>
                    {{ page.longtitle }}
                </h1>

                <p v-html="page.introtext" />
            </div>
        </hero>


        <section class="white">

            <div v-for="(p, id) in page.sections" class="content timeline" :key="id">
                <h2 v-html="p.pagetitle" />

                <div class="text" v-html="p.content" />

                <div class="img">
                    <img v-if="getImg(p)" :src="getImg(p)" loading="lazy" :alt="p.pagetitle" />
                </div>
            </div>

        </section>


        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import Hero from '@/components/HeroSection.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        Hero,
    },


    computed: {
        ...mapGetters([
            'base',
        ]),
    },


    methods: {
        getImg(p)
        {
            if (!Object.keys(p).indexOf('variables') < 0 || Object.keys(p.variables).indexOf('image') < 0) return false;

            return vm.base + p.variables.image;
        },
    },


    created()
    {
        vm = this;
    },
}
</script>
<template>
    <main>
        <hero :page="upNext">
            <div class="preview">
                <h1>
                    {{ upNext.pagetitle }}
                </h1>

                <p v-html="upNext.introtext" />

                <router-link :to="{ name: 'EventPage', params: { alias: upNext.alias }}" class="button tertiary">
                    <span>
                        Meer informatie
                    </span>

                    <i class="fas fa-caret-right fa-lg"></i>
                </router-link>
            </div>
        </hero>


        <category class="primary first" tag="upcoming" :events="upcomingEvents">
            <i class="far fa-calendar-check"></i>

            {{ page.variables.titleSchedule }}
        </category>


        <category class="secondary" tag="latest" :events="latest">
            <i class="far fa-calendar-star"></i>

            {{ page.variables.titleRecent }}
        </category>


        <category v-for="(c, id) in appliedCategories" :key="['cat', id].join('-')" :class="getSwiperClasses(c, id)" :events="c.events" :id="c.alias" :tag="c.alias">
            <i class="fas" :class="c.icon"></i>

            {{ c.name }}
        </category>

        <slot name="sponsors"></slot>
        <slot name="cta"></slot>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

import Category from '@/components/CategorySwiper.vue';
import Hero from '@/components/HeroSection.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        Category,
        Hero,
    },

    
    computed: {
        ...mapGetters([
            'categories',
            'events',
        ]),


        appliedCategories()
        {
            let cats = [];

            for (let i = 0; i < vm.categories.length; i++)
            {
                let c = vm.categories[i],
                    events = vm.getEventsByCategory(c.id);
                
                if (events.length > 0)
                {
                    cats.push({
                        alias: c.alias,
                        events: events,
                        icon: c.variables.icon,
                        name: c.pagetitle,
                    })
                }
            }

            return cats;
        },


        latest()
        {
            let latest = [];

            for (let i = vm.events.length - 1; i > 0; i--)
            {
                let e = vm.events[i];

                // Add only those that are public
                if (e.hidemenu == 0) latest.push(e);
            }

            return latest;
        },


        upcomingEvents()
        {
            let ue = {},
                events = [];
                // today = new Date();


            // Loop through all events
            for (let i = 0; i < vm.events.length; i++)
            {
                let e = vm.events[i],
                    date = Date.parse(e.variables.date.replace(' ', 'T'));
                
                // Add only those in the future, that are public
                // if (date >= today && e.hidemenu == 0)
                if (e.hidemenu == 0)
                {
                    ue[[date,i].join('-')] = e;
                }
            }


            // Order the results be date
            let keys = Object.keys(ue);
            keys.sort();

            for (let i = 0; i < keys.length; i++)
            {
                events.push(ue[keys[i]]);
            }


            return events;
        },


        upNext()
        {
            return vm.upcomingEvents.length > 0 ? vm.upcomingEvents[0] : vm.events[vm.events.length - 1];
        },
    },


    methods: {
        getEventsByCategory(cid)
        {
            let results = [];

            for (let i = 0; i < vm.upcomingEvents.length; i++)
            {
                let e = vm.upcomingEvents[i];

                if (e.variables.category == cid)
                {
                    results.push(e);
                }
            }

            return results;
        },


        getSwiperClasses(c, id)
        {
            let cl = [],
                secondary = [
                    'cultuur',
                    'cultureel',
                ],
                tertiary = [
                    'actief',
                    'culinair',
                ];

            if (id == vm.appliedCategories.length - 1) cl.push('last');

            if (secondary.indexOf(c.alias) >= 0)
            {
                cl.push('secondary');
            }
            else if (tertiary.indexOf(c.alias) >= 0)
            {
                cl.push('tertiary');
            }
            else
            {
                cl.push('primary');
            }

            return cl.join(' ');
        },
    },


	setup()
	{
		const { initButtons } = useFunctions();


		return {
            initButtons,
		};
	},


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.initButtons(0, false);
    },
}
</script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function usePage() {
	const route = useRoute();
	const store = useStore();


	const allPages = computed(() => {
		return store.state.pages;
	});

	const base = computed(() => {
		return store.state.base;
	});


	const page = computed(() => {
		// Get the pages for the current language
		let modalPaths = [
				// 'prints',
			],
			pages = allPages.value,
			page = {},
			routePath = route.path;

        // For print and product pages, get the overview page
		for (let i = 0; i < modalPaths.length; i++)
		{
			let splitter = '/' + modalPaths[i];

			if (routePath.split(splitter).length > 1)
			{
				routePath = routePath.split(splitter)[0] + splitter;
			}
		}

		if (typeof (pages) != 'undefined')
		{
            if (routePath.length > 1 && routePath.at(-1) != '/')
            {
                routePath += '/';
            }

            for (let i = 0; i < pages.length; i++)
			{
				let p = pages[i],
					pPath = '/' + p.path.replace(base.value, '/');

                if (pPath == '//') pPath = '/';

				if (routePath == pPath) page = p;
			}
	
			if (page)
			{
				// Create some additional paramters
				page.hasImage = Object.keys(page).indexOf('variables') >= 0 && Object.keys(page.variables).indexOf('image') >= 0;
				// page.title = Object.keys(page).indexOf('longtitle') >= 0 ? page.longtitle : page.pagetitle;
			}
		}
		
		return page ? page : {};
	});


	return {
		page: page,
	};
}